import { Link, Element } from 'react-scroll';
import { motion } from 'framer-motion';
// material
import { styled } from '@mui/material/styles';
import { Button, Container, Typography, Stack, Box, Grid } from '@mui/material';

//
import { varFadeIn, varFadeInUp, varWrapEnter, varFadeInDown } from '../animate';

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[400],
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center'
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '50vh',
    display: 'block',
    padding: theme.spacing(3, 0),
  }
}));

const ContentStyle = styled(Stack)(({ theme }) => ({
  zIndex: 10,
  maxWidth: '100%',
  margin: 'auto',
  textAlign: 'center',
  position: 'relative',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(5),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center', // Center content horizontally
  width: '80%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    width: '100%',
  }
}));

const HeroImgStyle = styled(motion.img)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 8,
  width: '100%',
  height: '100vh',
  margin: 'auto',
  position: 'absolute',
  objectFit: 'cover',
  objectPosition: 'center',
  [theme.breakpoints.down('md')]: {
    height: '100vh',
  }
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  display: 'block',
  objectFit: 'cover', // Ensure the image covers the container
  [theme.breakpoints.down('md')]: {
    width: '50%', // Adjust the width for smaller screens
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ImageBox2 = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  display: 'block',
  objectFit: 'cover',
  [theme.breakpoints.down('md')]: {
    width: '50%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto', // Add this line to move the component to the right
  },
  '& img': {
    width: '100%',
    height: 'auto',
  }
}));

const StyledAnchor = styled('a')({
  display: 'inline-block',
  width: '100%',
  textDecoration: 'none', // Remove underline from links
});

// ----------------------------------------------------------------------

export default function LandingHero() {
  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        <HeroImgStyle
          alt="get-started-get-discovered"
          src="/static/bannerBackground.png"
          variants={varFadeInUp}
        />

        <ContentStyle>
          <motion.div variants={varFadeInUp} style={{ width: '100%' }}>
            <Box
              sx={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }} // Center content
            >
              <Typography
                sx={{
                  color: 'common.white',
                  width: '100%',
                  mt: { xs: 1, sm: 0, md: 0 },
                  fontWeight: { xs: 500, md: 400 },
                  fontSize: { xs: '18px', md: '58px' }
                }}
              >
                THE NEW FACE OF A BETTER LABOR EFFICIENCY
              </Typography>
            </Box>

            <Box sx={{ width: '100%', mt: { xs: 2, md: 5 }, display: 'flex', justifyContent: 'center' }}>
              <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                <Grid item xs="auto">
                  <Link
                    sx={{
                      '&:hover': {
                        textDecoration: 'none'
                      }
                    }}
                    rel="noreferrer"
                    to='aboutSection'
                    smooth={true}
                    duration={500}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      sx={{ boxShadow: 'none', borderRadius: '35px', backgroundColor: 'white' }}
                    >
                      <Typography variant='body1' sx={{
                        padding: { xs: 0, md: 1 },
                        color: '#0000FF'
                      }}>  Learn More </Typography>
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs="auto">
                  <Link
                    sx={{
                      '&:hover': {
                        textDecoration: 'none'
                      }
                    }}
                    rel="noreferrer"
                    to='formSection'
                    smooth={true}
                    duration={500}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      sx={{ boxShadow: 'none', borderRadius: '35px', backgroundColor: '#195591' }}
                    >
                      <Typography variant='body1' sx={{
                        padding: { xs: 0, md: 1 }
                      }}>  Request Demo </Typography>
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </motion.div>
          <motion.div variants={varFadeInUp} style={{ width: '100%', marginTop: { xs: 2, md: 0 }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid item xs={6} sm={6} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5 }}>
              <StyledAnchor href="https://play.google.com/store/apps/details?id=com.starjobs&hl=en_US&pli=1&fbclid=IwZXh0bgNhZW0CMTAAAR14YO2hWdBFp3I6jt2qfyWp_c_5C4Qm2e3-J7rbu_ebN-TC084J-O9IJsc_aem_ZmFrZWR1bW15MTZieXRlcw" target="_blank" rel="noopener noreferrer">
                  <ImageBox2
                    component='img'
                    src='/static/googlePlay.png'
                    sx={{
                      width: { xs: '50%', md: '60%' }, // Adjust width for different screen sizes
                      height: 'auto',
                    }}
                  />
                </StyledAnchor>
                <StyledAnchor href="https://apps.apple.com/ph/app/starjobs-freelancers-app/id1660622210" target="_blank" rel="noopener noreferrer">
                  <ImageBox2
                    component='img'
                    src='/static/appStore.png'
                    sx={{
                      width: { xs: '50%', md: '60%' }, // Adjust width for different screen sizes
                      height: 'auto',
                      mt:{xs: 0, md:2, lg: 2} // Add margin-top to separate the images
                    }}
                  />
                </StyledAnchor>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <ImageBox
                  component="img"
                  src="/static/bannerImage.png"
                />
              </Grid>
            </Grid>
          </motion.div>
        </ContentStyle>
      </RootStyle>
      <Box sx={{ backgroundColor: '#0160bf', height: { xs: 'auto', md: '20vh' }, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'white', padding: { xs: 3, md: 0 } }}>
        <Typography variant="h3" sx={{ mb: 2, fontSize: { xs: '18px', md: '32px' } }}> Make it easier to find freelancers for your operations needs. </Typography>
        <Link
          sx={{
            '&:hover': {
              textDecoration: 'none'
            }
          }}
          rel="noreferrer"
          to='aboutSection'
          smooth={true}
          duration={500}

        >
          <Button
            size="large"
            variant="contained"
            sx={{ boxShadow: 'none', borderRadius: '35px', backgroundColor: 'white' }}
          >
            <Typography variant='body1' sx={{
              padding: { xs: 0, md: 1 },
              color: '#0000FF',
              fontSize: { xs: '12px', md: '18px' }
            }}>  Learn More </Typography>
          </Button>
        </Link>
      </Box>
    </>
  );
}
