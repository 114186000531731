import React, { useRef, useEffect, useState } from 'react';
import { Container, Typography, Grid, Box, List, ListItem, ListItemText } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import BulletIcon from '@mui/icons-material/FiberManualRecord';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { varFadeInLeft, varFadeInRight } from '../animate';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: 64,
  marginTop: '5vh',
  paddingBottom: 42,
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    marginTop: '1vh',
  },
}));

const TEXTS = [
  {
    id: 1,
    text: `Deliver quality and highly skilled profiles tailored fit to clients' standards`
  },
  {
    id: 2,
    text: 'Provide comprehensive pre-hiring assessment to all candidates and create synopsis'
  },
  {
    id: 3,
    text: 'Uphold the highest standard of professional ethics and conduct'
  },
  {
    id: 4,
    text: 'Ensure full compliance with the Public Employment Agency (DOLE-PEA)'
  }
];

export default function HomeAboutContent() {
  const videoRef = useRef(null);

  return (
    <RootStyle>
      <Box sx={{ width: '85%', display: 'flex', justifyContent: 'center' }}>
        <Grid container spacing={5} sx={{ width: '100%' }}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <motion.div variants={varFadeInLeft}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 2,
                  boxShadow: 0,
                  maxWidth: '100%',
                }}
              >
                <Box
                  component="video"
                  ref={videoRef}
                  sx={{
                    width: '100%',
                    height: { xs: '200px', sm: '300px', md: '350px' }, // Adjust height as needed
                    borderRadius: '10px',
                    objectFit: 'cover' // Maintain aspect ratio while covering the container
                  }}
                  controls
                >
                  <source src="/static/commercial.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </Box>
              </Box>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <motion.div variants={varFadeInRight} style={{ marginTop: 0, width: '100%', textAlign: 'center', height: '50vh', overflow: 'auto' }}>
              <Typography
                variant="h2"
                sx={{
                  color: '#0176BC',
                  margin: 'auto',
                  mb: 5,
                  mt: { xs: 5, sm: 0, md: 0 },
                  fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, // Reduced font size
                }}
              >
                About Us
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: 'common.black',
                  mb: 5,
                  fontWeight: 400,
                  textAlign: 'left',
                  fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' }, // Reduced font size
                }}
                component="p"
              >
                StarJobs is a versatile software platform that serves both
                employer (Client) and freelancers, Gigsters, and Independent
                Contractor (Jobster), acting as a medium between the two.
                This innovative app facilitates short-term contracts, work,
                and temporary engagement, catering to the growing dynamic needs
                of the modern job market.
              </Typography>
              <Typography variant="h3" sx={{ mb: 3, fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' } }}>
                Mission and Vision
              </Typography>
              <Typography variant="h4" sx={{ textAlign: 'left', fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}>
                Vision
              </Typography>
              <Typography
                variant="h5"
                sx={{ textAlign: 'left', fontWeight: 400, fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' } }}
              >
                Starjobs Executive Search Corp aims to be the leading local
                recruitment firm in the Philippines by the year 2025.
              </Typography>
              <Typography variant="h4" sx={{ textAlign: 'left', fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}>
                Mission
              </Typography>
              <Typography
                variant="h5"
                sx={{ textAlign: 'left', fontWeight: 400, fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' } }}
              >
                Starjobs Executive Search Corporation committed to:
              </Typography>
              <List>
                {TEXTS.map((item) => (
                  <ListItem key={item.id}>
                    <ListItemIcon>
                      <BulletIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' } }}
                        >
                          {item.text}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </motion.div>
          </Grid>
        </Grid>
      </Box>
    </RootStyle>
  );
}
