import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import { styled, useTheme } from '@mui/material/styles';
import { Card, Grid, Container, Stack, TextField, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import BulletIcon from '@mui/icons-material/FiberManualRecord'; // A common choice for bullet point
import PlaceIcon from '@mui/icons-material/Place';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { motion } from 'framer-motion';
import { varFadeInLeft, varFadeInRight } from '../animate';
import useMediaQuery from '@mui/material/useMediaQuery';

const RootStyle = styled('div')(() => ({
  position: 'relative',
  marginBottom: 64,
  paddingTop: 42,
  paddingBottom: 42,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const TEXTS = [
  {
    id: 1,
    text: '827 P. Paredes, Sampaloc, Manila, 1008 Metro Manila',
    icon: PlaceIcon
  },
  {
    id: 2,
    text: 'Monday - Friday 8am-5pm PST',
    icon: ScheduleIcon
  },
  {
    id: 3,
    text: '0995-042-6755',
    icon: PhoneIcon
  },
  {
    id: 4,
    text: 'danicadamilig.starjobs@gmail.com',
    icon: EmailIcon
  },
]

export default function HomeContactForm() {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      contact: '',
      message: '',
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const webhookURL = 'https://discord.com/api/webhooks/1251580941026197594/5yy3YFnvW68aECu7l8aJ1m2Z8qb007gLPJn7lww1vYDKdITip2ilCj6-QRoskk_UOWei';
        
        const payload = {
          content: `Name: ${values.name}\nEmail: ${values.email}\nContact: ${values.contact}\nCompany: ${values.company}`,
        };

        const response = await fetch(webhookURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          alert('Message sent successfully!');
          resetForm();
        } else {
          alert('Error sending message');
        }
      } catch (error) {
        console.error('Error sending message:', error);
        alert('Error sending message');
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid container spacing={5} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6} display="flex" flexDirection="column" alignItems="center">
            <motion.div variants={varFadeInRight} style={{ width: '100%' }}>
              <Typography
                variant={isMobile ? 'h6' : 'h5'}
                sx={{
                  color: 'common.black',
                  marginBottom: 2,
                  textAlign: 'left',
                }}
              >
                CONTACTS
              </Typography>
              <Typography
                variant={isMobile ? 'h5' : 'h4'}
                sx={{
                  color: 'common.black',
                  fontWeight: 400,
                  textAlign: 'left',
                }}
              >
                For client inquiries, sample demos and cost estimates, use the contact form.
              </Typography>
              <List>
                {TEXTS.map((item) => {
                  const IconComponent = item.icon;
                  return (
                    <ListItem key={item.id}>
                      <ListItemIcon>
                        <IconComponent fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body1" sx={{ fontSize: isMobile ? '1rem' : '1.25rem' }}>
                            {item.text}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div variants={varFadeInLeft}>
              <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Card sx={{ px: 3, py: 5, backgroundColor: '#0176BC' }}>
                    <Typography variant='h3' color='white' sx={{mb:'3vh'}}> Contact Us </Typography>
                    <Stack spacing={3}>
                      <TextField
                        fullWidth
                        label="Full Name"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        sx={{ backgroundColor: 'white', borderRadius: '12px' }}
                      />
                      <TextField
                        fullWidth
                        label="Email address"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        sx={{ backgroundColor: 'white', borderRadius: '12px' }}
                      />
                      <TextField
                        fullWidth
                        label="Contact No."
                        {...getFieldProps('contact')}
                        error={Boolean(touched.contact && errors.contact)}
                        helperText={touched.contact && errors.contact}
                        sx={{ backgroundColor: 'white', borderRadius: '12px' }}
                      />
                      <TextField
                        fullWidth
                        label="Company Name"
                        {...getFieldProps('company')}
                        error={Boolean(touched.message && errors.message)}
                        helperText={touched.message && errors.message}
                        sx={{ backgroundColor: 'white', borderRadius: '12px' }}
                      />
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{
                          color: 'blue.light',
                          backgroundColor: 'white',
                          transition: 'all 0.4s ease',
                          borderRadius: '20px',
                          '&:hover': {
                            color: 'white',
                            backgroundColor: 'blue.light',
                            transition: 'all 0.4s ease',
                          },
                        }}
                        loading={isSubmitting}
                      >
                        Submit
                      </LoadingButton>
                    </Stack>
                  </Card>
                </Form>
              </FormikProvider>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
